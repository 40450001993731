import { AssetReference } from '@manifest-cyber/types/interface/db';
import {
  InterfaceOssIngest,
  InterfaceSbom,
} from '@manifest-cyber/types/interface/dbTables';
import { Button, Divider, Flex, Text } from '@mantine/core';
import copy from 'copy-to-clipboard';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../../components/ClickableRegion';
import Icon from '../../../components/Icon';
import Labels from '../../../components/Labels';
import { ManifestTag } from '../../../components/ManifestTag/ManifestTag';
import appConfig from '../../../configs/appConfig';
import featureFlagDisciminators from '../../../configs/featureFlags';
import { MappedAsset } from '../../../hooks/queries/useFetchAsset';
import { useFetchUser } from '../../../hooks/queries/useFetchUser';
import { useAuth } from '../../../hooks/useAuth';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import replaceKeysWithStringValues, {
  checkForScaryLocations,
} from '../../../lib/abbreviateCommonStrings';
import formatBigCount from '../../../lib/formatBigCount';
import normalizeAuthor from '../../../lib/normalizeAuthor';
import timeSince from '../../../lib/timeSince';
import assetStyles from '../Asset.module.scss';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import { useFetchOrganization } from '../../../hooks/queries/useFetchOrganization';

interface Props {
  fetchedAsset?: MappedAsset;
  fetchedIngest?: InterfaceOssIngest;
  fetchedSboms: InterfaceSbom[];
  setLabelEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEnrichedSBOM: boolean;
}

export const AssetAboutTab = ({
  fetchedAsset,
  fetchedSboms,
  setLabelEditModalOpen,
  fetchedIngest,
  isEnrichedSBOM,
}: Props) => {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const [signatureCopied, setSignatureCopied] = useState(false);

  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });
  const modifiedSBOMDownloadFeatureFlag =
    !isLoadingCurrentOrganization &&
    fetchedCurrentOrganization?.enabledFeatures?.some(
      (feature) => feature === 'modified-sbom',
    );

  const { data: uploaderUserInfo } = useFetchUser({
    userId: fetchedAsset?.latestSbom?.uploadUserId?.toString()!,
  });

  const imageMetadataTags: any = Object.values(
    fetchedAsset?.latestSbom?.metadata?.[0]?.Image ?? {},
  )?.[0];

  const refinedLinks: AssetReference[] = [];

  const refinedAssetLinks = fetchedAsset?.references?.flatMap(
    (thisAssetLink: AssetReference) => {
      if (
        thisAssetLink?.url &&
        thisAssetLink?.url?.length > 0 &&
        (thisAssetLink?.url?.includes('http://') ||
          thisAssetLink?.url?.includes('https://'))
      ) {
        const thisLink = refinedLinks.find((link) => link.url === thisAssetLink?.url);
        if (!thisLink) {
          refinedLinks.push(thisAssetLink);
          return [thisAssetLink];
        }
      }

      return [];
    },
  );

  const signature =
    fetchedAsset?.latestSbom?.attestation?.signatures &&
    fetchedAsset?.latestSbom?.attestation?.signatures?.[0]?.sig
      ? fetchedAsset?.latestSbom?.attestation?.signatures?.[0]?.sig
      : '';

  const setCopySignature = () => {
    setSignatureCopied(true);

    setTimeout(() => {
      setSignatureCopied(false);
    }, 4000);
  };

  if (fetchedIngest) {
  }

  const githubMeta = fetchedIngest?.metadata?.github || null;

  const cleanupContributorLocation = (location: any) => {
    const forceLowercase = (str: string) => str.toLowerCase();
    const emptyStrings = ['null', 'unknown', 'anonymous', 'undefined', 'empty', ''];

    if (
      !location ||
      typeof location !== 'string' ||
      emptyStrings.includes(forceLowercase(`${location}`)?.trim())
    ) {
      return <span className="not-provided">{t('global.na')}</span>;
    }

    let locationClasses = checkForScaryLocations(location, {});
    location = replaceKeysWithStringValues(location);

    // Add spaces after any trailing commas
    return (
      <span className={`${locationClasses?.join(' ')}`}>
        {location.replace(/,(?=[^\s])/g, ', ')}
      </span>
    );
  };

  const getAssetHint = (): string | null => {
    if (fetchedAsset?.changeMade && fetchedAsset?.originatedFromEnrichedSbom) {
      return t('page.asset.about-content.general.hints.enriched-and-edited');
    } else if (fetchedAsset?.changeMade) {
      return t('page.asset.about-content.general.hints.edited');
    } else if (fetchedAsset?.originatedFromEnrichedSbom) {
      return t('page.asset.about-content.general.hints.enriched');
    }

    return null;
  };

  return (
    <div className="asset-about-content">
      {fetchedAsset && (
        <>
          <div className="view-cont about anim-slideInLeftShort">
            <h3>{t('page.asset.about-content.general.header')}</h3>
            {modifiedSBOMDownloadFeatureFlag &&
            (fetchedAsset?.changeMade || fetchedAsset?.originatedFromEnrichedSbom) ? (
              <>
                <Flex gap={4} mb={8}>
                  <Icon icon="lightbulb-on" className={assetStyles.assetHintIcon} />
                  <Text size="sm">{getAssetHint()}</Text>
                </Flex>
                <Divider />
              </>
            ) : null}
            <ul>
              {/* Asset Name */}
              <li>
                <div className="label">{t('page.asset.name')}</div>
                <div className="value">{fetchedAsset?.name}</div>
              </li>
              {/* Asset Version */}
              <li>
                <div className="label">{t('page.asset.version')}</div>
                <div className="value">{fetchedAsset?.version}</div>
              </li>
              {/* Checks for OSS license first, since that's usually clearer. Then looks for license[0] on orgasset */}
              {fetchedIngest && fetchedIngest?.license ? (
                <li>
                  <div className="label">
                    {t('page.asset.about-content.general.license')}
                  </div>
                  <div className="value">
                    {
                      // @ts-ignore
                      fetchedIngest?.license?.url?.length > 0 ? (
                        <ClickableRegion
                          regionLabel={t('page.uploads.ingests.gitUrlLink')}
                          href={
                            // @ts-ignore
                            `${fetchedIngest?.license?.url}`
                          }
                          target="_blank"
                          className="giturl-link"
                        >
                          <Icon icon="github" iconStyle="fab" />
                          <span>
                            {
                              // @ts-ignore
                              fetchedIngest?.license?.name
                            }
                          </span>
                          <Icon icon="arrow-up-right-from-square" />
                        </ClickableRegion>
                      ) : (
                        <span>
                          {
                            // @ts-ignore
                            fetchedIngest?.license?.name
                          }
                        </span>
                      )
                    }
                  </div>
                </li>
              ) : (!fetchedIngest || !fetchedIngest?.license) &&
                fetchedAsset?.licensesData &&
                fetchedAsset?.licensesData?.length > 0 &&
                fetchedAsset?.licensesData?.[0]?.shortName ? (
                <li>
                  <div className="label">
                    {t('page.asset.about-content.general.license')}
                  </div>
                  <div className="value">
                    {fetchedAsset?.licensesData?.[0]?.shortName}
                  </div>
                </li>
              ) : (fetchedAsset.licenses?.length ?? 0) > 0 &&
                fetchedAsset.licenses?.[0]?.name ? (
                <li>
                  <div className="label">
                    {t('page.asset.about-content.general.license')}
                  </div>
                  <div className="value">{fetchedAsset.licenses[0].name}</div>
                </li>
              ) : (
                <li>
                  <div className="label">
                    {t('page.asset.about-content.general.license')}
                  </div>
                  <div className="value">{t('global.notProvided')}</div>
                </li>
              )}
              {/* Developed By */}
              <li>
                <div className="label">{t('page.asset.developedBy')}</div>
                <div className="value">
                  {fetchedIngest && fetchedIngest?.metadata?.groupName ? (
                    <ClickableRegion
                      regionLabel={t('page.uploads.ingests.gitUrlLink')}
                      href={`https://github.com/${fetchedIngest?.metadata?.groupName}`}
                      target="_blank"
                      className="giturl-link"
                    >
                      <Icon icon="github" iconStyle="fab" />
                      <span>{fetchedIngest?.metadata?.groupName}</span>
                      <Icon icon="arrow-up-right-from-square" />
                    </ClickableRegion>
                  ) : (
                    <span>{normalizeAuthor(fetchedAsset, t)}</span>
                  )}
                </div>
              </li>
              {/* Date Created */}
              <li>
                <div className="label">{t('page.asset.uploaded')}</div>
                <div className="value">
                  {uploaderUserInfo?.decryptedEmails?.[0] ? (
                    <a href={`mailto:${uploaderUserInfo?.decryptedEmails?.[0]}`}>
                      {uploaderUserInfo?.decryptedEmails?.[0]}
                    </a>
                  ) : null}
                  {fetchedAsset?.dateCreated
                    ? DateTime.fromISO(
                        fetchedAsset?.dateCreated.toString(),
                      ).toLocaleString(DateTime.DATETIME_MED)
                    : t('global.notProvided')}
                </div>
              </li>
              {/* Date Modified */}
              <li>
                <div className="label">{t('page.asset.updated')}</div>
                <div className="value">
                  {uploaderUserInfo?.decryptedEmails?.[0] ? (
                    <a href={`mailto:${uploaderUserInfo?.decryptedEmails?.[0]}`}>
                      {uploaderUserInfo?.decryptedEmails?.[0]}
                    </a>
                  ) : null}
                  {fetchedAsset?.dateModified
                    ? DateTime.fromISO(
                        fetchedAsset?.dateModified.toString(),
                      ).toLocaleString(DateTime.DATETIME_MED)
                    : t('global.notProvided')}
                </div>
              </li>

              <>
                {fetchedAsset.lastReleaseDateText && (
                  <li>
                    <div className="label">{t('page.asset.lastReleaseDate')}</div>
                    <div className="value">{fetchedAsset.lastReleaseDateText}</div>
                  </li>
                )}
                {fetchedAsset.levelOfSupportText && (
                  <li>
                    <div className="label">{t('page.asset.levelOfSupport')}</div>
                    <div className="value">{fetchedAsset.levelOfSupportText}</div>
                  </li>
                )}
                {fetchedAsset.endOfSupportText && (
                  <li>
                    <div className="label">{t('page.asset.endOfSupport')}</div>
                    <div className="value">{fetchedAsset.endOfSupportText}</div>
                  </li>
                )}
              </>

              {/* Attestation */}
              <li>
                <div className="label">{t('page.asset.attestation.label')}</div>
                <div className="value">
                  {signature ? (
                    <ClickableRegion
                      regionLabel={`${signature}`}
                      onClick={() => {
                        copy(`${signature}`);
                        setCopySignature();
                      }}
                      className="asset-attestation"
                    >
                      {signatureCopied ? (
                        <span>
                          <Icon icon="check-double" />
                          {t(`page.asset.attestation.signatureCopyBtnSignatureCopied`)}
                        </span>
                      ) : (
                        <span style={{ color: 'var(--color-primary)' }}>
                          {signature.length > 12
                            ? `${signature.slice(0, 6).toLowerCase()}...${signature
                                .slice(-6)
                                .toLowerCase()}  `
                            : `${signature}  `}{' '}
                          <Icon
                            icon="clone"
                            iconStyle="fal"
                            rotation={90}
                            color="var(--color-primary)"
                          />
                        </span>
                      )}
                    </ClickableRegion>
                  ) : (
                    <span className="not-provided">
                      {t(`page.asset.attestation.missing-full`)}
                    </span>
                  )}
                </div>
              </li>
              {/* Links */}
              <li>
                <div className="label">{t('page.asset.links')}</div>
                <div className="value">
                  {refinedAssetLinks && refinedAssetLinks?.length > 0 ? (
                    refinedAssetLinks?.map((reference) => (
                      <li className="asset-links">
                        <ClickableRegion
                          regionLabel={`${reference.type || 'Click to view'}`}
                          href={reference.url}
                          target="_blank"
                        >
                          <span>{reference.type}</span>
                        </ClickableRegion>
                      </li>
                    ))
                  ) : (
                    <span className="not-provided">{t('global.notProvided')}</span>
                  )}
                </div>
              </li>
              {/* Warnings */}
              <li>
                <div className="label">{t('page.asset.warnings')}</div>
                <div className="value" style={{ width: '100%', flexShrink: 1 }}>
                  {parseInt(`${fetchedAsset?.countVulnerabilities?.inKEV}`) > 0 ? (
                    <span className="kev-badger-wrapper">
                      <ManifestTag variant="kev" />
                    </span>
                  ) : parseInt(`${fetchedAsset?.countVulnerabilities?.inKEVPreviously}`) >
                    0 ? (
                    <span className="kev-badger-wrapper">
                      <ManifestTag variant="prev-kev" />
                    </span>
                  ) : null}
                </div>
              </li>
              <li>
                <div className="label">{t('page.asset.labels')}</div>
                <div className="value" style={{ width: '100%', flexShrink: 1 }}>
                  {
                    <Labels
                      entity={fetchedAsset}
                      {...(hasWriteAccess && {
                        onEditButtonClick: () => setLabelEditModalOpen(true),
                      })}
                      showWarnings={false}
                      rounded
                    />
                  }
                </div>
              </li>
              {fetchedSboms && fetchedSboms.length > 0 && (
                <>
                  <li>
                    <div className="label">{t('global.downloads')}</div>
                    <div className="value sbom-downloads">
                      {!modifiedSBOMDownloadFeatureFlag && isEnrichedSBOM ? (
                        <ClickableRegion
                          href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${fetchedSboms[0]?._id}?redirect=1&enriched=true`}
                        >
                          <Button
                            leftIcon={<Icon icon="arrow-down-to-bracket" />}
                            variant="default"
                            className="sbom-downloads__action"
                          >
                            {t('page.asset.enrichedSbom')}
                          </Button>
                        </ClickableRegion>
                      ) : null}

                      {!modifiedSBOMDownloadFeatureFlag && fetchedAsset.changeMade ? (
                        <ClickableRegion
                          href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${fetchedSboms[0]?._id}?redirect=1&editedSbom=true`}
                        >
                          <Button
                            leftIcon={<Icon icon="arrow-down-to-bracket" />}
                            variant="default"
                            className="sbom-downloads__action"
                          >
                            {t('page.asset.edited-sbom')}
                          </Button>
                        </ClickableRegion>
                      ) : null}

                      {modifiedSBOMDownloadFeatureFlag &&
                      (isEnrichedSBOM || fetchedAsset.changeMade) ? (
                        <ClickableRegion
                          href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${fetchedSboms[0]?._id}?redirect=1&modified=true`}
                        >
                          <Button
                            leftIcon={<Icon icon="arrow-down-to-bracket" />}
                            variant="default"
                            className="sbom-downloads__action"
                          >
                            {t('page.asset.modified-sbom')}
                          </Button>
                        </ClickableRegion>
                      ) : null}

                      <ClickableRegion
                        href={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${fetchedSboms[0]?._id}?redirect=1`}
                      >
                        <Button
                          leftIcon={<Icon icon="arrow-down-to-bracket" />}
                          variant="default"
                          className="sbom-downloads__action"
                        >
                          {t('page.asset.original-sbom')}
                        </Button>
                      </ClickableRegion>
                    </div>
                  </li>
                  {/* SBOM uploaded */}
                  {/* <li>
                    <div className="label">{t('page.asset.uploaded-sbom')}</div>
                    <div className="value">
                      {fetchedAsset?.latestSbom?.dateCreated
                        ? DateTime.fromISO(
                          fetchedAsset?.latestSbom?.dateCreated.toString(),
                        ).toLocaleString(DateTime.DATETIME_MED)
                        : t('global.notProvided')}
                    </div>
                  </li> */}
                </>
              )}

              {((fetchedAsset?.latestSbom?.imageId &&
                fetchedAsset?.latestSbom?.imageId?.length > 0) ||
                (fetchedAsset?.latestSbom?.containerName &&
                  fetchedAsset?.latestSbom?.containerName?.length > 0) ||
                (fetchedAsset?.latestSbom?.repoTags &&
                  fetchedAsset?.latestSbom?.repoTags?.length > 0)) && (
                <div className="horizontal-separator" />
              )}

              {fetchedAsset?.latestSbom?.imageId &&
                fetchedAsset?.latestSbom?.imageId?.length > 0 && (
                  <li>
                    <div className="label">{t('page.asset.imageId')}</div>
                    <div className="value">{fetchedAsset?.latestSbom?.imageId}</div>
                  </li>
                )}
              {fetchedAsset?.latestSbom?.containerName &&
                fetchedAsset?.latestSbom?.containerName?.length > 0 && (
                  <li>
                    <div className="label">{t('page.asset.containerName')}</div>
                    <div className="value">{fetchedAsset?.latestSbom?.containerName}</div>
                  </li>
                )}
              {fetchedAsset?.latestSbom?.repoTags &&
                fetchedAsset?.latestSbom?.repoTags?.length > 0 && (
                  <li>
                    <div className="label">{t('page.asset.repoTags')}</div>
                    <div className="value">
                      {(Array.isArray(fetchedAsset?.latestSbom?.repoTags)
                        ? fetchedAsset?.latestSbom?.repoTags?.join(', ')
                        : fetchedAsset?.latestSbom?.repoTags
                      ).trim()}
                    </div>
                  </li>
                )}
            </ul>
          </div>
          {fetchedIngest &&
            fetchedIngest?._id &&
            fetchedIngest?.gitUrl &&
            fetchedIngest?.gitUrl?.length > 0 && (
              <div className="view-cont wider about anim-slideInLeftShort">
                <h3>{t('page.asset.about-content.analysis.header')}</h3>
                <div className="header-info">
                  <div className="info-git-url">
                    <ClickableRegion
                      regionLabel={t('page.uploads.ingests.gitUrlLink')}
                      href={`${fetchedIngest?.gitUrl}`}
                      target="_blank"
                      className="giturl-link primary"
                    >
                      <Icon icon="github" iconStyle="fab" />
                      <span>{fetchedIngest?.gitUrl}</span>
                      <Icon icon="arrow-up-right-from-square" />
                    </ClickableRegion>
                  </div>
                </div>
                <div className="header-info info-split">
                  <div>
                    <Icon icon="star" />
                    <span>
                      {t('page.asset.about-content.analysis.stars', {
                        value: githubMeta?.stargazers_count
                          ? formatBigCount(githubMeta?.stargazers_count)
                          : t('global.na'),
                      })}
                    </span>
                  </div>
                  <div className="adjust-icon-down">
                    <Icon icon="code-branch" />
                    <span>
                      {t('page.asset.about-content.analysis.forks', {
                        value: githubMeta?.forks_count
                          ? formatBigCount(githubMeta?.forks_count)
                          : t('global.na'),
                      })}
                    </span>
                  </div>
                  <div className="adjust-icon-down">
                    <Icon icon="calendar" />
                    <span>
                      {t('page.asset.about-content.analysis.repo-created', {
                        date: githubMeta?.created_at
                          ? DateTime.fromISO(githubMeta?.created_at).toLocaleString(
                              DateTime.DATE_MED,
                            )
                          : t('global.na'),
                        timeAgo: githubMeta?.created_at
                          ? DateTime.fromISO(githubMeta?.created_at).toRelative()
                          : t('global.na'),
                      })}
                    </span>
                  </div>
                </div>
                <div className="horizontal-separator" />
                <ul>
                  {/* Owner */}
                  <li>
                    <div className="label">
                      {t('page.asset.about-content.analysis.owner')}
                    </div>
                    <div className="value">
                      {githubMeta?.owner?.login ? (
                        <ClickableRegion
                          regionLabel={t('page.uploads.ingests.gitUrlLink')}
                          href={githubMeta?.owner?.html_url}
                          target="_blank"
                          className="giturl-link"
                        >
                          {/* <Icon icon='github' iconStyle='fab' /> */}
                          <span>
                            {t('page.asset.about-content.analysis.owner-value', {
                              owner: githubMeta?.owner?.login,
                              ownerType: githubMeta?.owner?.type,
                            })}
                          </span>
                          <Icon icon="arrow-up-right-from-square" />
                        </ClickableRegion>
                      ) : (
                        <span>{t('global.na')}</span>
                      )}
                    </div>
                  </li>
                  {/* TODO: Package Manager */}
                  {/* <li>
                  <div className="label">{t('page.asset.about-content.analysis.pkgMgr')}</div>
                  <div className="value">{githubMeta?.owner?.login ? (
                    <ClickableRegion
                      regionLabel={t('page.uploads.ingests.gitUrlLink')}
                      href={githubMeta?.owner?.html_url}
                      target='_blank'
                      className='giturl-link'
                    >
                      <span>{
                        t('page.asset.about-content.analysis.owner-value', {
                          owner: githubMeta?.owner?.login,
                          ownerType: githubMeta?.owner?.type,
                        })
                      }</span>
                      <Icon icon='arrow-up-right-from-square' />
                    </ClickableRegion>
                  ) : (
                    <span>{t('global.na')}</span>
                  )}</div>
                </li> */}
                  {/* Last Commit Date */}
                  <li>
                    <div className="label">
                      {t('page.asset.about-content.analysis.lastCommitDate')}
                    </div>
                    <div className="value">
                      {githubMeta?.pushed_at ? (
                        <span>
                          {t('page.asset.about-content.analysis.lastCommitDate-value', {
                            date: githubMeta?.pushed_at
                              ? DateTime.fromISO(githubMeta?.pushed_at).toLocaleString(
                                  DateTime.DATE_MED,
                                )
                              : t('global.na'),
                            timeAgo: githubMeta?.pushed_at
                              ? DateTime.fromISO(githubMeta?.pushed_at).toRelative()
                              : t('global.na'),
                          })}
                        </span>
                      ) : (
                        <span>{t('global.na')}</span>
                      )}
                    </div>
                  </li>
                  {/* Contributors */}
                  <li>
                    <div className="label">
                      {t('page.asset.about-content.analysis.contributors')}
                    </div>
                    <div className="value">
                      {fetchedIngest?.countUniqueContributors ? (
                        <span>
                          {(fetchedIngest?.countUniqueContributors).toLocaleString()}
                        </span>
                      ) : (
                        <span>{t('global.na')}</span>
                      )}
                    </div>
                  </li>
                  {/* Top 10 Contributors */}
                  <li className="not-flex">
                    <div className="label">
                      {t('page.asset.about-content.analysis.contributors-top')}
                    </div>
                    <div className="value inner-list">
                      {fetchedIngest?.mostActiveContributors ? (
                        <>
                          {fetchedIngest?.mostActiveContributors?.map(
                            (contributor, index) => {
                              return (
                                <div className="contributor-item-cont">
                                  <div className="contributor-item">
                                    <span className="contributor-name">
                                      {contributor?.name}
                                    </span>
                                    <span className="contributor-location">
                                      <Icon icon="map-location" />
                                      {cleanupContributorLocation(
                                        contributor?.location || '',
                                      )}
                                    </span>
                                  </div>
                                  <div className="contributor-item-lower">
                                    <span className="contributor-created">
                                      <Icon icon="stopwatch" />
                                      Created:{' '}
                                      {`${
                                        contributor?.accountCreated
                                          ? timeSince(contributor?.accountCreated, t)
                                          : 'N/A (Anon)'
                                      }`}
                                    </span>
                                    <span className="contributor-commits">
                                      <strong>
                                        {contributor?.commits?.toLocaleString()}
                                      </strong>{' '}
                                      Contributions
                                    </span>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </>
                      ) : (
                        <span>{t('global.na')}</span>
                      )}
                    </div>
                  </li>
                  {/* Commit Hash */}
                  <li>
                    <div className="label">
                      {t('page.asset.about-content.analysis.commitHash')}
                    </div>
                    <div className="value">
                      {fetchedIngest?.commitHash ? (
                        <span
                          className="copyable"
                          onClick={() => copy(`${fetchedIngest?.commitHash}`)}
                        >
                          {fetchedIngest?.commitHash}
                          <Icon
                            onClick={() => copy(`${fetchedIngest?.commitHash}`)}
                            icon="copy"
                          />
                        </span>
                      ) : (
                        <span>{t('global.na')}</span>
                      )}
                    </div>
                  </li>
                </ul>
                <div className="horizontal-separator" />
                <ul>
                  {/* ClamAV Scan */}
                  <li>
                    <div className="label">
                      {t('pdf-export.asset-details.about.repo.clamav')}
                    </div>
                    <div className="value">
                      {fetchedIngest?.metadata?.clamScanResults ? (
                        <span>
                          {fetchedIngest?.metadata?.clamScanResults?.infectedFiles === 0
                            ? t('pdf-export.asset-details.about.repo.clamav-clean')
                            : t('pdf-export.asset-details.about.repo.clamav-infected', {
                                value: fetchedIngest?.metadata?.clamScanResults
                                  ?.infectedFiles as number,
                              })}
                        </span>
                      ) : (
                        <span>
                          {t('pdf-export.asset-details.about.repo.clamav-not-run')}
                        </span>
                      )}
                    </div>
                  </li>
                  {/* OpenSSF */}
                  <li>
                    <div className="label">
                      {t('pdf-export.asset-details.about.repo.openssf-score')}
                    </div>
                    <div className="value">
                      {fetchedIngest?.metadata?.openSsfScorecardUrl ? (
                        <span>
                          {fetchedIngest?.metadata?.openSsfScorecardUrl?.['no-scorecard']
                            ? t(
                                'pdf-export.asset-details.about.repo.openssf-not-available',
                              )
                            : t(
                                'pdf-export.asset-details.about.repo.openssf-score-value',
                                {
                                  value: fetchedIngest?.metadata?.openSsfScorecardUrl
                                    ?.score as number,
                                  countChecks: fetchedIngest?.metadata
                                    ?.openSsfScorecardUrl?.checks?.length as number,
                                },
                              )}
                        </span>
                      ) : (
                        <span>
                          {t('pdf-export.asset-details.about.repo.openssf-not-available')}
                        </span>
                      )}

                      {fetchedIngest?.metadata?.openSsfScorecardUrl?.score && (
                        <ClickableRegion
                          regionLabel={t(
                            'pdf-export.asset-details.about.repo.openssf-score-link',
                          )}
                          href={
                            fetchedIngest?.metadata?.openSsfScorecardUrl?.scorecardUrl
                          }
                          target="_blank"
                          className="scorecard-link"
                        >
                          <span>
                            {t('pdf-export.asset-details.about.repo.openssf-score-link')}
                          </span>
                          <Icon icon="arrow-up-right-from-square" />
                        </ClickableRegion>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            )}
        </>
      )}

      {imageMetadataTags && imageMetadataTags?.labels && (
        <div className="view-cont image-tags anim-slideInLeftShort">
          <h4>{t('page.asset.metadata-tags')}</h4>
          <ul className="tree-view">
            {Object.keys(imageMetadataTags?.labels).map((tag: any) => {
              try {
                if (Array.isArray(imageMetadataTags?.labels[tag])) {
                  return (
                    <li key={`${imageMetadataTags?.labels[tag]}`}>
                      <div className="label">{tag}</div>
                      <div className="value">
                        {imageMetadataTags?.labels[tag]?.join(', ').trim()}
                      </div>
                    </li>
                  );
                }

                if (typeof imageMetadataTags?.labels[tag] === 'object') {
                  return (
                    <li key={`${JSON.stringify(imageMetadataTags?.labels[tag])}`}>
                      <div className="label">{tag}</div>
                      <div className="value">
                        {Object.values(imageMetadataTags?.labels[tag])?.join(', ').trim()}
                      </div>
                    </li>
                  );
                }

                return (
                  <li key={`${imageMetadataTags?.labels[tag]}`}>
                    <div className="label">{tag}</div>
                    <div className="value">{imageMetadataTags?.labels[tag]}</div>
                  </li>
                );
              } catch (err) {
                return null;
              }
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
