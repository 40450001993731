import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import {
  ActionIcon,
  Badge,
  Flex,
  Group,
  Menu,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { postGenerateSBOM } from '../../../api/product.api';
import { useAuth } from '../../../hooks/useAuth';
import { ManifestNotifications } from '../../../hooks/utils/useNotifications';
import { rejectFailedRequestOrEmptyData } from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { Logger } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { useFetchProductCapabilities } from '../../../pages/Products/products/useFetchProductCapabilities';
import { usePutProductLabels } from '../../../pages/Products/products/usePutProductLabels';
import { Dropdown } from '../../Dropdown/Dropdown';
import Icon from '../../Icon';
import Labels from '../../Labels';
import ManageLabelsModal from '../../Labels/ManageLabelsModal';
import {
  DownloadProductSbomModalsteps,
  DownloadSBOMModal,
} from '../DownloadSBOMModal/DownloadSBOMModal';
import { ProductModal } from '../ProductModal/ProductModal';
import './ProductHeader.scss';

interface ProductHeaderProps {
  product: InterfaceOrganizationProduct;
  orgName: string;
}

export const ProductHeader = ({ product, orgName }: ProductHeaderProps) => {
  const { t } = useTranslation();
  const { checkUserAccess, user } = useAuth();
  const hasWriteAccess = checkUserAccess('write');

  const { data, isLoading } = useFetchProductCapabilities({
    productId: product._id?.toString(),
  });

  const { mutateAsync: putProductLabels } = usePutProductLabels();

  const [isDownloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [labelEditModalOpen, setLabelEditModalOpen] = useState(false);
  const [downloadSbomModal, setDownloadSbomModal] = useState<
    | {
        initialStep: DownloadProductSbomModalsteps;
      }
    | undefined
  >();
  const unableToMergeReason = useMemo(() => {
    if (data?.capabilities.reason === 'asset formats mismatching')
      return t('product.unable-to-generate-sbom-formats-mismatching');
    if (data?.capabilities.reason === 'assets limit reached')
      return t('product.unable-to-generate-sbom-limit-reached');
    return '';
  }, [data]);

  const productFormData = {
    id: product._id?.toString() || '',
    manufacturer: product.manufacturer || '',
    description: product.description || '',
    name: product.name || '',
    version: product.version || '',
    types: product.types ? product.types.map((type) => type._id.toString()) : [],
    labels: product.labelIds ? product.labelIds.map((labelId) => labelId.toString()) : [],
  };

  const downloadProduct = async () => {
    if (!product._id) {
      return;
    }

    let format = '';
    // find the non-lossy format, this is the default format, if none found, use the first one
    for (const key in data?.capabilities.mergeOutputs) {
      if (!data?.capabilities.mergeOutputs[key]?.lossy) {
        format = key;
        break;
      }
    }

    // if feature flag is enabled, use the first lossy format for now.
    if (!format) {
      format = Object.keys(data?.capabilities.mergeOutputs!)?.[0]!;
    }

    try {
      setDownloadSbomModal({
        initialStep: 'emailOnTheWay',
      });
      await postGenerateSBOM(
        product._id.toString(),
        product.name || '',
        product.version || '',
        format,
      );
    } catch (error) {
      Logger.error('Error generating product sbom', error);
    }
  };

  const downloadVDR = async ({
    productId,
    userId,
  }: {
    productId: string;
    userId: string;
  }) => {
    try {
      const response = await AxiosProxy.post({
        url: 'file-request',
        body: {
          type: 'vdr',
          format: 'csv',
          targetType: 'product',
          targetIds: [productId],
          requestorId: userId,
        },
      });
      await rejectFailedRequestOrEmptyData<{
        state: string;
      }>(response);

      ManifestNotifications.showInfoNotification({
        title: t('product.downloadNotifications.successTitle'),
        message: t('product.downloadNotifications.successDescription'),
      });
    } catch (error) {
      ManifestNotifications.showErrorNotification({
        title: t('product.downloadNotifications.errorTitle'),
        message: t('product.downloadNotifications.errorDescription'),
      });
      Logger.error('Error downloading VDR', error);
    }
  };

  return (
    <Flex className="product-header" direction="column" gap={8}>
      <Flex justify="space-between" align="center">
        <Flex direction="column" gap={8}>
          <Group spacing="xs">
            {product?.relationship === 'first' && (
              <Badge
                mx={0}
                sx={{
                  width: '35px',
                  '& span': {
                    textOverflow: 'none',
                    overflow: 'visible',
                  },
                }}
              >
                {t('global.first')}
              </Badge>
            )}
            {product?.relationship === 'third' && (
              <Badge
                mx={0}
                color="gray"
                sx={{
                  width: '35px',
                  '& span': {
                    textOverflow: 'none',
                    overflow: 'visible',
                  },
                }}
              >
                {t('global.third')}
              </Badge>
            )}
            <Text className="product-header__organization" size="sm">
              {orgName}
            </Text>
          </Group>
          <Group spacing="xs">
            <Title className="product-header__product-name" order={2}>
              {product.name}
            </Title>
            <Badge className="product-header__version" variant="filled" size="xl">
              {product.version}
            </Badge>
          </Group>
          <Group spacing="xl">
            <Group spacing="xs">
              <Icon icon="calendar" size="xl" color="#1EBCE2" />
              <Text>
                {t('product.created-at', {
                  date: DateTime.fromISO(`${product?.dateCreated}`).toLocaleString({
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  }),
                })}
              </Text>
              <Tooltip label={t('page.product.labelsDescription')}>
                <span className="product-header__tag-icon">
                  <Icon icon="tag" />
                </span>
              </Tooltip>
              <Labels
                entity={product}
                condensed
                {...(hasWriteAccess
                  ? { onClick: () => setLabelEditModalOpen(true) }
                  : {})}
                rounded
              />
            </Group>
            <Group spacing="xs">
              {product.metadata?.sku ? (
                <>
                  <Icon icon="qrcode" size="xl" color="#1EBCE2" />
                  <Text>
                    <span className="product-header__sku">{t('product.sku')}</span>{' '}
                    {product.metadata?.sku}
                  </Text>
                </>
              ) : null}
            </Group>
          </Group>
        </Flex>
        <Group spacing="xs">
          <Dropdown
            // withinPortal
            position="bottom-end"
            targetProps={{
              label: t('product.download'),
              variant: 'default',
              leftIcon: (
                <TextComponent color="muted">
                  <Icon icon="arrow-down-to-bracket" />
                </TextComponent>
              ),
              disabled: !(product.assetCount && product.assetCount > 0),
            }}
          >
            <Dropdown.Label>{t('product.sbom')}</Dropdown.Label>
            <Tooltip
              disabled={data?.capabilities.canMerge || !unableToMergeReason}
              label={unableToMergeReason}
            >
              <Dropdown.Item
                onClick={downloadProduct}
                disabled={!data?.capabilities.canMerge}
                icon={
                  <TextComponent color="muted">
                    <Icon icon="arrow-down-to-bracket" />
                  </TextComponent>
                }
              >
                {t('product.downloadSbom')}
              </Dropdown.Item>
            </Tooltip>
            <>
              <Dropdown.Label>
                <TextComponent variant="headerAllCaps" color="primary">
                  {t('product.vdr')}
                </TextComponent>
              </Dropdown.Label>
              <Dropdown.Item
                onClick={() =>
                  downloadVDR({
                    productId: product._id?.toString() ?? '',
                    userId: user?._id?.toString() ?? '',
                  })
                }
                icon={
                  <TextComponent color="muted">
                    <Icon icon="arrow-down-to-bracket" />
                  </TextComponent>
                }
                disabled={!(product.assetCount && product.assetCount > 0)}
              >
                {t('product.downloadVdr')}
              </Dropdown.Item>
            </>
          </Dropdown>

          {downloadSbomModal && (
            <DownloadSBOMModal
              initialStep={downloadSbomModal.initialStep}
              onClose={() => setDownloadSbomModal(undefined)}
              product={product}
            />
          )}
          {/*
               @mrsufgi: enable once we want to rollout download modal
              <Menu withinPortal position="bottom-end">
                <DownloadSBOMModal
                  opened={isDownloadModalOpen}
                  setOpened={setDownloadModalOpen}
                  product={product}
                />
                
                {!data?.capabilities.canMerge ? (
                  <span className="disabled-btn-wrapper" data-testid="tooltip">
                    <Button
                      className="product-header__download"
                      leftIcon={<Icon icon="arrow-down-to-bracket" />}
                      rightIcon={<Icon icon="chevron-down" />}
                      variant="default"
                      disabled
                      loading={isLoading}
                    >
                      {t('product.download')}
                    </Button>
                  </span>
                ) : (
                  <Menu.Target>
                    <Button
                      variant="default"
                      leftIcon={<Icon icon="arrow-down-to-bracket" />}
                      rightIcon={<Icon icon="chevron-down" />}
                      className="product-header__download"
                    >
                      {t('product.download')}
                    </Button>
                  </Menu.Target>
                )}
                <Menu.Dropdown className="product-header__dropdown">
                  <div className="header">{t('product.sbom')}</div>
                  <Menu.Item
                    className="manifest-menu__item"
                    onClick={() => {
                      let format = '';
                      for (const key in data?.capabilities.mergeOutputs) {
                        if (!data?.capabilities.mergeOutputs[key]?.lossy) {
                          format = key;
                          break;
                        }
                      }
                      if (format) {
                        downloadSbomHandler(format);
                      } else {
                        setDownloadModalOpen(true);
                      }
                    }}
                    disabled={!data?.capabilities.canMerge}
                    icon={<Icon icon="arrow-down-to-bracket" />}
                  >
                    <span>{t('product.downloadSbom')}</span>
                  </Menu.Item>

                  {Object.keys(data?.capabilities.mergeOutputs || {}).length > 1 && (
                    <Menu.Item
                      className="manifest-menu__item"
                      onClick={() => setDownloadModalOpen(true)}
                      disabled={!data?.capabilities.canMerge}
                      icon={<Icon icon="arrow-down-to-bracket" />}
                    >
                      <span>{t('product.downloadSbomAs')}</span>
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu> */}

          {hasWriteAccess && (
            <Menu withinPortal position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="filled"
                  className="product-header__actions"
                  size="lg"
                  aria-label={t('product.actions')}
                >
                  <Icon icon="ellipsis-vertical" size="lg" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => {
                    setIsEditMode(true);
                    setModalOpen(true);
                  }}
                  icon={<Icon icon="pen" size="sm" />}
                >
                  {t('global.edit')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      </Flex>
      <ProductModal
        opened={isModalOpen}
        setOpened={setModalOpen}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        editProductData={productFormData}
      />
      <ManageLabelsModal
        key="product-header-labels-modal"
        putLabels={async (data) => {
          putProductLabels(data);
        }}
        entityType="product"
        labelIds={{
          [product._id?.toString() as string]:
            product.labelIds?.map((labelId) => labelId.toString()) || [],
        }}
        open={labelEditModalOpen}
        onClose={() => setLabelEditModalOpen(false)}
      />
    </Flex>
  );
};
